"use strict";

//import { Alert, Collapse } from 'bootstrap.native';
import Alert from 'bootstrap/js/dist/alert.js';
import Collapse from 'bootstrap/js/dist/collapse.js';
import initBackTop from './main/back_top';
import initInlineForm from './main/inline_forms';

function initComponents() {
  [Alert, Collapse];
  // [Alert, Collapse].forEach((c) => {
  //   document.querySelectorAll(c.selector).forEach((el) => c.init(el as HTMLElement));
  // });

  initBackTop(document.getElementById("back-top") as HTMLElement);
  document.querySelectorAll('.js-form form').forEach((el) => initInlineForm(el as HTMLFormElement));
}

// bulk initialize all components
document.body ? initComponents() : addEventListener('DOMContentLoaded', initComponents, {once: true});
