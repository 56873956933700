export default function initBackTop(back_top: HTMLElement) {
  let shown = false;

  back_top.addEventListener("click", onBackTop);
  window.addEventListener("scroll", onScroll);
  onScroll(); // initialize on reload

  function onScroll() {
    // @ts-ignore
    if (document.scrollingElement.scrollTop > 100) {
      if (!shown) {
        back_top.classList.add("show");
        shown = true;
      }
    } else {
      if (shown) {
        back_top.classList.remove("show");
        shown = false;
      }
    }
  }

  function onBackTop(event: Event) {
    event.preventDefault();
    // or requestAnimationFrame with 800ms
    // @ts-ignore
    document.scrollingElement.scrollTo(0, 0);
  }
}
